const { flatMap } = require('lodash');

angular.module('editingPlace')

.controller('EditingPlace',
    ['$state', 'Editing', '$q', '$uibModal', '$scope', '$uibModalStack', 'Modal', 'Place', '$stateParams', 'account', 'configs',
        function ($state, Editing, $q, $uibModal, $scope, $uibModalStack, Modal, Place, $stateParams, account, configs){
        var vm = this;
        $state.vm = vm;

        vm.loading = true;
        vm.admin = account.admin
        vm.listing_id = $state.params.listing_id
        vm.place_id = $stateParams.place_id
        vm.listing = {};
        vm.amenities = {
            'popular': {
                'label': 'Popular',
                'list': [
                    {'name': 'ESSENTIALS', 'label': 'Essentials', 'description': 'Towels, bed sheets, soap, toilet paper, and pillows'},
                    {'name': 'AC', 'label': 'Air conditioning'},
                    {'name': 'CLEANING_PRODUCTS', 'label': 'Cleaning products '},
                    {'name': 'COOKING_BASICS', 'label': 'Cooking basics'},
                    {'name': 'LAPTOP_FRIENDLY_WORKSPACE', 'label': 'Dedicated workspace', 'description': 'Guests have a desk or table that’s used just for working, along with a comfortable chair.'},
                    {'name': 'DISHES_AND_SILVERWARE', 'label': 'Dishes and silverware', 'description': 'Bowls, chopsticks, plates, cups, etc.'},
                    {'name': 'DRYER', 'label': 'Dryer'},
                    {'name': 'WASHER', 'label': 'Washer'},
                    {'name': 'HAIR_DRYER', 'label': 'Hair dryer'},
                    {'name': 'HEATING', 'label': 'Heating'},
                    {'name': 'JACUZZI', 'label': 'Hot tub'},
                    {'name': 'KITCHEN', 'label': 'Kitchen'},
                    {'name': 'POOL', 'label': 'Pool'},
                    {'name': 'TV', 'label': 'TV'},
                    {'name': 'WIRELESS_INTERNET', 'label': 'Wifi'},
                ],
            },
            'bathroom': {
                'label': 'Bathroom',
                'list': [
                    {'name': 'BATHTUB', 'label': 'Bathtub'},
                    {'name': 'BIDET', 'label': 'Bidet'},
                    {'name': 'BODY_SOAP', 'label': 'Body soap'},
                    {'name': 'CLEANING_PRODUCTS', 'label': 'Cleaning products'},
                    {'name': 'CONDITIONER', 'label': 'Conditioner'},
                    {'name': 'HAIR_DRYER', 'label': 'Hair dryer'},
                    {'name': 'HOT_WATER', 'label': 'Hot water'},
                    {'name': 'RAIN_SHOWER', 'label': 'Outdoor shower'}, // ?
                    {'name': 'SHAMPOO', 'label': 'Shampoo'},
                    {'name': 'SHOWER_GEL', 'label': 'Shower gel'},
                ],
            },
            'bedroom_and_laundry': {
                'label': 'Bedroom and laundry',
                'list': [
                    {'name': 'ESSENTIALS', 'label': 'Essentials', 'description': 'Towels, bed sheets, soap, toilet paper, and pillows'},
                    {'name': 'BED_LINENS', 'label': 'Bed linens'},
                    {'name': 'WARDROBE_OR_CLOSET', 'label': 'Clothing storage'},
                    {'name': 'DRYER', 'label': 'Dryer'},
                    {'name': 'CLOTHES_DRYING_RACK', 'label': 'Drying rack for clothing'},
                    {'name': 'EXTRA_PILLOWS_AND_BLANKETS', 'label': 'Extra pillows and blankets'},
                    {'name': 'HANGERS', 'label': 'Hangers'},
                    {'name': 'IRON', 'label': 'Iron'},
                    {'name': 'MOSQUITO_NET', 'label': 'Mosquito net'},
                    {'name': 'ROOM_DARKENING_SHADES', 'label': 'Room-darkening shades'},
                    {'name': 'SAFE', 'label': 'Safe'},
                    {'name': 'WASHER', 'label': 'Washer'},
                ]
            },
            'entertainment': {
                'label': 'Entertainment',
                'list': [
                    {'name': 'ETHERNET_CONNECTION', 'label': 'Ethernet connection'},
                    {'name': 'EXERCISE_EQUIPMENT', 'label': 'Exercise equipment'},
                    {'name': 'GAME_CONSOLE', 'label': 'Game console'},
                    {'name': 'PIANO', 'label': 'Piano'},
                    {'name': 'PING_PONG_TABLE', 'label': 'Ping pong table'},
                    {'name': 'POOL_TABLE', 'label': 'Pool table'},
                    {'name': 'RECORD_PLAYER', 'label': 'Record player'},
                    {'name': 'SOUND_SYSTEM', 'label': 'Sound system'},
                    {'name': 'TV', 'label': 'TV'},
                ]
            },
            'family': {
                'label': 'Family',
                'list': [
                    {'name': 'BABY_BATH', 'label': 'Baby bath'},
                    {'name': 'BABY_MONITOR', 'label': 'Baby monitor'},
                    {'name': 'STAIR_GATES', 'label': 'Baby safety gates'},
                    {'name': 'BABYSITTER_RECOMMENDATIONS', 'label': 'Babysitter recommendations'},
                    {'name': 'BOARD_GAMES', 'label': 'Board games'},
                    {'name': 'CHANGING_TABLE', 'label': 'Changing table'},
                    {'name': 'CHILDRENS_BOOKS_AND_TOYS', 'label': 'Children’s books and toys'},
                    {'name': 'CHILDRENS_DINNERWARE', 'label': 'Children’s dinnerware'},
                    {'name': 'CRIB', 'label': 'Crib'},
                    {'name': 'FIREPLACE_GUARDS', 'label': 'Fireplace guards'},
                    {'name': 'HIGH_CHAIR', 'label': 'High chair'},
                    {'name': 'OUTLET_COVERS', 'label': 'Outlet covers'},
                    {'name': 'PACK_N_PLAY_TRAVEL_CRIB', 'label': 'Pack ’n play/Travel crib'},
                    {'name': 'TABLE_CORNER_GUARDS', 'label': 'Table corner guards'},
                    {'name': 'WINDOW_GUARDS', 'label': 'Window guards'},
                ]
            },
            'heating_and_cooling': {
                'label': 'Heating and cooling',
                'list': [
                    {'name': 'AC', 'label': 'Air conditioning'},
                    {'name': 'CEILING_FAN', 'label': 'Ceiling fan'},
                    {'name': 'HEATING', 'label': 'Heating'},
                    {'name': 'FIREPLACE', 'label': 'Indoor fireplace'},
                    {'name': 'PORTABLE_FANS', 'label': 'Portable fans'},
                ]
            },
            'home_safety': {
                'label': 'Home safety',
                'list': [
                    {'name': 'CARBON_MONOXIDE_DETECTOR', 'label': 'Carbon monoxide alarm', 'description': 'Check your local laws, which may require a working carbon monoxide detector in every room'},
                    {'name': 'FIRE_EXTINGUISHER', 'label': 'Fire extinguisher'},
                    {'name': 'FIRST_AID_KIT', 'label': 'First aid kit'},
                    {'name': 'SMOKE_DETECTOR', 'label': 'Smoke alarm', 'description': 'Check your local laws, which may require a working smoke detector in every room'},
                ]
            },
            'internet_and_safety': {
                'label': 'Internet and safety',
                'list': [
                    {'name': 'LAPTOP_FRIENDLY_WORKSPACE', 'label': 'Dedicated workspace', 'description': 'Guests have a desk or table that’s used just for working, along with a comfortable chair'},
                    {'name': 'POCKET_WIFI', 'label': 'Pocket wifi'},
                    {'name': 'WIRELESS_INTERNET', 'label': 'Wifi', 'description': 'Available throughout the listing'},
                ]
            },
            'kitchen_and_dining': {
                'label': 'Kitchen and dining',
                'list': [
                    {'name': 'BAKING_SHEET', 'label': 'Baking sheet'},
                    {'name': 'BARBEQUE_UTENSILS', 'label': 'Barbecue utensils', 'description': 'Grill, charcoal, bamboo skewers/iron skewers, etc.'},
                    {'name': 'BREAD_MAKER', 'label': 'Bread maker'},
                    {'name': 'BLENDER', 'label': 'Blender'},
                    {'name': 'COFFEE', 'label': 'Coffee'},
                    {'name': 'COFFEE_MAKER', 'label': 'Coffee maker'},
                    {'name': 'COOKING_BASICS', 'label': 'Cooking basics', 'description': 'Pots and pans, oil, salt and pepper'},
                    {'name': 'DINING_TABLE', 'label': 'Dining table'},
                    {'name': 'DISHES_AND_SILVERWARE', 'label': 'Dishes and silverware', 'description': 'Bowls, chopsticks, plates, cups, etc.'},
                    {'name': 'DISHWASHER', 'label': 'Dishwasher'},
                    {'name': 'FREEZER', 'label': 'Freezer'},
                    {'name': 'HOT_WATER_KETTLE', 'label': 'Hot water kettle'},
                    {'name': 'KITCHEN', 'label': 'Kitchen', 'description': 'Space where guests can cook their own meals'},
                    {'name': 'MICROWAVE', 'label': 'Microwave'},
                    {'name': 'MINI_FRIDGE', 'label': 'Mini fridge'},
                    {'name': 'OVEN', 'label': 'Oven'},
                    {'name': 'REFRIGERATOR', 'label': 'Refrigerator'},
                    {'name': 'RICE_MAKER', 'label': 'Rice maker'},
                    {'name': 'STOVE', 'label': 'Stove'},
                    {'name': 'TOASTER', 'label': 'Toaster'},
                    {'name': 'TRASH_COMPACTER', 'label': 'Trash compactor'},
                    {'name': 'WINE_GLASSES', 'label': 'Wine glasses'},
                ]
            },
            'location_features': {
                'label': 'Location Features',
                'list': [
                    {'name': 'BEACH_ACCESS', 'label': 'Beach access', 'description': 'Guests can enjoy a nearby beach'},
                    {'name': 'LAKE_ACCESS', 'label': 'Lake access', 'description': 'Guests can get to a lake using a path or dock'},
                    {'name': 'LAUNDROMAT_NEARBY', 'label': 'Laundromat nearby'},
                    {'name': 'PRIVATE_ENTRANCE', 'label': 'Private entrance', 'description': 'Separate street or building entrance'},
                    {'name': 'RESORT_ACCESS', 'label': 'Resort access', 'description': 'Guests can use nearby resort facilities'},
                    {'name': 'SKI_IN_SKI_OUT', 'label': 'Ski-in/Ski-out', 'description': 'Guests can access ski lifts without driving or taking paid transportation'},
                    {'name': 'WATERFRONT', 'label': 'Waterfront', 'description': 'Right next to a body of water'},
                ]
            },
            'outdoor': {
                'label': 'Outdoor',
                'list': [
                    {'name': 'GARDEN_OR_BACKYARD', 'label': 'Backyard', 'description': 'An open space on the property usually covered in grass'},
                    {'name': 'BBQ_AREA', 'label': 'BBQ grill'},
                    {'name': 'BEACH_ESSENTIALS', 'label': 'Beach essentials', 'description': 'Beach towels, umbrella, beach blanket, snorkeling gear'},
                    {'name': 'BIKES_FOR_RENT', 'label': 'Bikes'},
                    {'name': 'BOAT_SLIP', 'label': 'Boat slip'},
                    {'name': 'FIRE_PIT', 'label': 'Fire pit'},
                    {'name': 'HAMMOCK', 'label': 'Hammock'},
                    {'name': 'KAYAK', 'label': 'Kayak'},
                    {'name': 'OUTDOOR_SEATING', 'label': 'Outdoor furniture'}, //?
                    {'name': 'OUTDOOR_KITCHEN', 'label': 'Outdoor kitchen'},
                    {'name': 'PATIO_OR_BELCONY', 'label': 'Patio or balcony'},
                ]
            },
            'parking_and_facilities': {
                'label': 'Parking and facilities',
                'list': [
                    {'name': 'ELEVATOR', 'label': 'Elevator', 'description': 'The home or building has an elevator that’s at least 52 inches deep and a doorway at least 32 inches wide.'},
                    {'name': 'EV_CHARGER', 'label': 'EV charger', 'description': 'Guests can charge their electric vehicles on the property.'},
                    {'name': 'FREE_PARKING', 'label': 'Free parking on premises'},
                    {'name': 'STREET_PARKING', 'label': 'Free street parking'},
                    {'name': 'GYM', 'label': 'Gym'},
                    {'name': 'JACUZZI', 'label': 'Hot tub'},
                    {'name': 'PAID_PARKING', 'label': 'Paid parking off premises'},
                    {'name': 'PAID_PARKING_ON_PREMISES', 'label': 'Paid parking on premises'},
                    {'name': 'POOL', 'label': 'Pool'},
                    {'name': 'SAUNA', 'label': 'Sauna'},
                    {'name': 'SINGLE_LEVEL_HOME', 'label': 'Single level home', 'description': 'No stairs in home'},
                ]
            },
            'services': {
                'label': 'Services',
                'list': [
                    {'name': 'BREAKFAST', 'label': 'Breakfast', 'description': 'Breakfast is provided'},
                    {'name': 'CLEANING_BEFORE_CHECKOUT', 'label': 'Cleaning before checkout'},
                    {'name': 'LONG_TERM_STAYS_ALLOWED', 'label': 'Long term stays allowed', 'description': 'Allow stay for 28 days or more'},
                    {'name': 'LUGGAGE_DROPOFF_ALLOWED', 'label': 'Luggage dropoff allowed', 'description': 'For guests\' convenience when they have early arrival or late departure'},
                ]
            },
        }
        vm.guest_safety = {
            'list': [
                {
                    'name': 'pool_or_jacuzzi_with_no_fence',
                    'label': 'Pool/hot tub without a gate or lock',
                    'description': 'Guests will have direct access to an ungated / unlocked in-ground or above-ground swimming pool or hot tub.'
                },
                {
                    'name': 'lake_or_river_or_water_body',
                    'label': 'Nearby lake, river, other body of water',
                    'description': 'Guests will have direct, unrestricted access to any permanent natural or artificial body of water located directly on or next to the property. Ex: ocean/beach, pond, creek, wetlands.'
                },
                {
                    'name': 'climbing_or_play_structure',
                    'label': 'Climbing or play structure',
                    'description': 'Guests (including children) will have access to structures or items intended for climbing or playing on. Ex: swing, slide, playset, climbing ropes.'
                },
                {
                    'name': 'heights_with_no_fence',
                    'label': 'Heights without rails or protection',
                    'description': 'Guests will have access to an area or structure that reaches a height of more than 1.4 meters/4.6 feet and does not have a rail or other protection. Ex: balcony, roof, terrace.'
                },
                {
                    'name': 'animals',
                    'label': 'Potentially dangerous animal',
                    'description': 'Guests (and their pets) will be around or near wild or domesticated animals that could cause harm because of their behavior or size. Ex: horse, mountain lion, dog that growls or bites.'
                },
                {
                    'name': 'surveillance',
                    'label': 'Security cameras/audio recording devices',
                    'description': 'The property has a security camera or recording device capable of recording or sending video, audio, or still images. Airbnb requires hosts to inform guests of any such camera or device located in a common area—even if it will be turned off during a guest’s stay. Airbnb prohibits security cameras or recording devices in private spaces like bedrooms, bathrooms, or sleeping areas.'
                },
                // {
                //     'name': '',
                //     'label': 'Carbon monoxide alarm',
                //     'description': 'The property has an alarm that detects and warns about the presence of carbon monoxide gas. Check your local laws for specific requirements.'
                // },
                // {
                //     'name': '',
                //     'label': 'Smoke alarm',
                //     'description': 'The property has an alarm that detects and warns about the presence of smoke and fire. Check your local laws for specific requirements.'
                // },
                {
                    'name': 'requires_stairs',
                    'label': 'Must climb stairs',
                    'description': 'Guests can expect to walk up and down stairs during their stay.'
                },
                {
                    'name': 'potential_noise',
                    'label': 'Potential for noise',
                    'description': 'Guests can expect to hear some noise during their stay. Ex: traffic, construction, nearby businesses.'
                },
                {
                    'name': 'has_pets',
                    'label': 'Pet(s) live on property',
                    'description': 'Guests may come across pets and experience a little animal love during their stay.'
                },
                {
                    'name': 'limited_parking',
                    'label': 'No parking on property',
                    'description': 'This property does not have dedicated parking for guests.'
                },
                {
                    'name': 'shared_spaces',
                    'label': 'Some spaces are shared',
                    'description': 'Guests can expect to share spaces with other people during their stay. Ex: kitchen, bathroom, patio.'
                },
                {
                    'name': 'limited_amenities',
                    'label': 'Amenity limitations',
                    'description': 'Guests shouldn’t expect some essentials they may be used to having when traveling. Ex: wifi, running water, indoor shower.'
                },
                {
                    'name': 'weapons',
                    'label': 'Weapons on property',
                    'description': 'Guests should be aware that there is at least one weapon stored on this property. Airbnb requires all weapons to be properly stored and secured.'
                }
            ],
        }
        vm.day_hours = [];
        for (var i = 9; i <= 24; i++)
            vm.day_hours.push({
                label: i + ':00',
                value: `${i}`
            });

        vm.availability_window_periods = [
            {label: 'Dates unavailable by default', value: 0},
            {label: '3 months in advance', value: 90},
            {label: '6 months in advance', value: 180},
            {label: '9 months in advance', value: 270},
            {label: '12 months in advance', value: 365},
            {label: 'All future dates', value: -1},
        ];

        const exec_requests = async (requests) => {
            const data = []
            for(const req of requests){
                data.push(await req())
            }
            return data
        }

        vm.get_place = async function () {
            vm.place = await Place.get(vm.place_id);
        }

        vm.get_listing = async function () {
            try {

                const api_calls = [
                    () => Editing.get_listing(vm.listing_id),
                    () => Editing.get_rooms(vm.listing_id),
                    () => Editing.get_booking_settings(vm.listing_id),
                    () => Editing.get_pricing_settings(vm.listing_id),
                    () => Editing.get_availability_rules(vm.listing_id),
                    () => Promise.resolve(vm.place),
                    () => Editing.get_all_listing_photos(vm.listing_id),
                    () => Editing.get_description(vm.listing_id)
                ]

                const [
                    listing,
                    listing_rooms,
                    booking_settings,
                    pricing_settings,
                    availability_rules,
                    other_informations,
                    listing_photos,
                    listing_description
                ] = await exec_requests(api_calls)
                $scope.$apply(function () {
                    vm.listing = {
                        listing: {...listing.listing, room_type_category: 'entire_home'},
                        listing_description: listing_description.listing_descriptions[0],
                        listing_rooms: listing_rooms.listing_rooms,
                        booking_settings: booking_settings.booking_setting,
                        pricing_settings: pricing_settings.pricing_setting,
                        availability_rules : availability_rules.availability_rule,
                        other_informations: other_informations,
                        listing_photos: listing_photos.listing_photos,
                    };

                    vm.listing.listing_rooms.forEach(room => {
                        if(room.room_type !== 'bedroom' && room.room_type !== 'full_bathroom' && room.room_type !== 'half_bathroom') {
                            vm.listing.additionnal_area = vm.listing.additionnal_area + 1;
                        }

                        if(!room.is_private) {
                            vm.listing.shared_space = false;
                        }
                    })

                    vm.listing.pricing_settings.weekly_price_factor = Math.round((1-vm.listing.pricing_settings.weekly_price_factor)*100);
                    vm.listing.pricing_settings.monthly_price_factor = Math.round((1-vm.listing.pricing_settings.monthly_price_factor)*100);

                    vm.listing.other_informations.deposit = parseInt(vm.listing.other_informations.deposit)

                    vm.loading = false;
                });

            } catch(e){
                vm.loading = false;
                var title = 'An error occurred';
                var body = `Please help us improve the service by contacting us at <b><a href="mailto: ${configs.contact_email}">${configs.contact_email}</a></b>`;

                Modal.inform(title, body);
            }

        }


        vm.get_place().then(() => {
            vm.get_listing()
        })


        /*
            LISTING BASIC
         */

        vm.picture_upload = function (file) {
            Editing.add_photo(vm.listing_id, file)
                .then(function (response) {
                   
                    Editing.get_all_listing_photos(vm.listing_id)
                        .then(function (response) {
                            vm.listing.listing_photos = response.listing_photos
                        }).catch(function (response) {
                            console.log('An error occurs. Unable to retrieve all the photos. Error: ', response)
                        })
                }).catch(function (response) {
                console.log('An error occurs. Unable to upload the picture. Error: ', response)
            });
        };

        vm.pictures_upload = function (files) {
            angular.forEach(files,
                function(file) {
                    if(file.size > 23068672)
                        return Modal.inform("Error", "Your file is too large, maximum file size is 20MB");
                    vm.picture_upload(file);
                });
        };

        vm.delete_picture = function (index) {
            vm.photo_id = vm.listing.listing_photos[index].id

            Editing.delete_photo(vm.photo_id, vm.listing_id)
                .then(function (response) {

                    Editing.get_all_listing_photos(vm.listing_id)
                        .then(function (response) {
                            vm.listing.listing_photos = response.listing_photos
                        }).catch(function (response) {
                            console.log('An error occurs. Unable to retrieve all the photos. Error: ', response)
                        })

                }).catch(function (response) {
                    console.log('An error occurs. Unable to delete photo. Error: ', response)
                })

        }

        vm.set_as_principal = function (index) {
            vm.photo_id = vm.listing.listing_photos[index].id

            Editing.edit_photo(vm.photo_id, 0, vm.listing_id)
                .then(function (response) {

                    Editing.get_all_listing_photos(vm.listing_id)
                        .then(function (response) {
                            vm.listing.listing_photos = response.listing_photos
                        }).catch(function (response) {
                            console.log('An error occurs. Unable to retrieve all the photos. Error: ', response)
                        })

                }).catch(function (response) {
                    console.log('An error occurs. Unable to delete photo. Error: ', response)
                })

        }

        vm.big_drag_n_drop = function () {
            vm.listing.listing_photos.forEach((photo, index) => {
                if(photo.sort_order !== index + 1) {
                    if(Math.abs((index+1) - photo.sort_order) >1) {
                        Editing.edit_photo(photo.id, index+1, vm.listing_id)
                            .then(function (response) {
                                Editing.get_all_listing_photos(vm.listing_id)
                                    .then(function (response) {
                                        vm.listing.listing_photos = response.listing_photos;
                                    })
                            })
                    }
                }
            })
        }

        vm.little_drag_n_drop = function () {
            vm.listing.listing_photos.forEach((photo, index) => {
                if (photo.sort_order !== index + 1) {
                    Editing.edit_photo(photo.id, index +1, vm.listing_id)
                        .then(function (response) {
                            Editing.get_all_listing_photos(vm.listing_id)
                                .then(function (response) {
                                    vm.listing.listing_photos = response.listing_photos;
                                })
                        })
                }
            })
        }

        vm.sortable = {
            animation: 500,
            onSort: function (evt) {
                const drag_interval = []
                vm.listing.listing_photos.forEach((photo, index) => {
                    if(photo.sort_order !== index + 1) {
                        drag_interval.push(Math.abs((index+1) - photo.sort_order))
                    }
                })
                const max_drag_interval = drag_interval.filter(interval => interval>1)
                if(max_drag_interval.length !== 0) {
                    vm.big_drag_n_drop();
                } else {
                    vm.little_drag_n_drop();
                }
            }
        }

        vm.edit_description = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            /* Utilisation d'une variable de scope pour éviter de surcharger la variable globale dans vm
            Copie de la valeur sans la référence pour éviter d'alterer la variable globale
             */
            modal_scope.listing_description = {...vm.listing.listing_description}

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/description.modal.html'),
                scope: modal_scope,
              });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
              Editing.edit_description(
                vm.listing_id,
                modal_scope.listing_description,
                modal_scope.listing_description.locale
              ).then(() => {

                  /* Define the place */
                  let place = {
                      ...modal_scope.listing_description,
                  };
                  place.description = modal_scope.listing_description.summary
                  place.id = vm.place_id;

                  return Place.update(place)
                      .then(() => {
                          vm.listing.listing_description = place // update the interface
                          $uibModalStack.dismissAll();
                      })
                      .catch(function (response) {
                        console.log(
                          "An error occurs. Unable to save the modifications. Error: ",
                          response
                        );
                      });
              });
            };
            return deferred.promise;
        }

        vm.edit_nb_of_guest = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.listing = {...vm.listing.listing}

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/nb_of_guests.modal.html'),
                scope: modal_scope,
              });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {

                Editing.edit_listing(vm.listing_id, modal_scope.listing)
                    .then(function (response) {
                        vm.listing.listing = modal_scope.listing
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                        console.log('An error occurs. Unable to change the number of guest. Error: ', response)
                });
            }
            return deferred.promise;
        }

        vm.edit_amenities = function (context) {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();

            modal_scope.context = vm.amenities[context].label;
            modal_scope.fields = vm.amenities[context].list;
            modal_scope.listing = {...vm.listing.listing};

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/switchs.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function(){
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {

                Editing.edit_listing(vm.listing_id, modal_scope.listing)
                    .then(function (response) {
                        vm.listing.listing = modal_scope.listing
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                    console.log('An error occurs. Unable to save the amenities modifications. Error: ', response)
                });
            }
            return deferred.promise;
        }

        vm.edit_address = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.listing = {...vm.listing.listing}
            modal_scope.place = {
                place_search: ''
            }

            modal_scope.place_set = function() {
                var address = this.getPlace();
                var street = '';
                var postal_code = '';
                var city = '';
                var country = '';
                var area = ''

                if (!address.address_components) {
                    vm.place_search = '';
                    return;
                }

                for (var i = 0; i < address.address_components.length; i++) {
                    var c = address.address_components[i];

                    if (c.types.indexOf('street_number') != -1)
                        street = c.long_name;

                    if (c.types.indexOf('route') != -1)
                        street += ' ' + c.long_name;

                    if (c.types.indexOf('locality') != -1)
                        city = c.long_name;

                    if (c.types.indexOf('postal_code') != -1)
                        postal_code = c.long_name;

                    if (c.types.indexOf('country') != -1)
                        country = c.long_name;
                    if (c.types.indexOf('administrative_area_level_1') != -1)
                        area = c.long_name;
                };

                modal_scope.listing = {
                    ...modal_scope.listing,
                    street,
                    state: country + ' / ' + area,
                    zipcode: postal_code,
                    city,
                }
            }

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/address.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {

                Editing.edit_listing(vm.listing_id, modal_scope.listing)
                    .then(function (response) {
                        if (response.data && response.data.error_message) {
                            Modal.inform("Error", response.data.error_message);
                            console.log('An error occurs. Unable to save the address. Error: ', response)
                            return;
                        }

                        vm.listing.listing = modal_scope.listing

                        if (!vm.place.address)
                            vm.place.address = {}

                        vm.place.address.street = modal_scope.listing.street;
                        vm.place.address.postal_code = modal_scope.listing.zipcode;
                        vm.place.address.city = modal_scope.listing.city;
                        vm.place.address.country = modal_scope.listing.state;

                        Place.update(vm.place)
                        .then(function (response) {
                            $uibModalStack.dismissAll();
                        }).catch(function (response) {
                            console.log('An error occurs. Unable to save in database. Error: ', response)
                        });
                    })
            }
            return deferred.promise;
        }

        vm.check_room_validity = function (listing_rooms) {
            for(const room of listing_rooms) {
                if (room.is_delete) {
                    // Ignored room
                    continue;
                }
                if (!room.room_type) {
                    return { value: true, type: 'invalid_room' }
                }
                if (['living_room', 'bedroom'].includes(room.room_type)) {
                    if (!room.beds?.length) {
                        return { value: true, type: 'invalid_bed' };
                    }
                    for (const bed of room.beds) {
                        if (!bed.type) {
                            return { value: true, type: 'invalid_bed' }
                        }
                    }
                }
            }
            return { value: false, type: '' }
        }

        vm.apply_room_update = async function(room, room_numbers) {
            const room_numbers_delete = (to_del) => {
                if (to_del === 0 || to_del) {
                    const room_number_index = room_numbers.indexOf(to_del);
                    if (room_number_index >= 0) {
                        room_numbers.splice(room_number_index, 1);
                    }
                }
            }
            const room_update_room_number = (room) => {
                if (['living_room', 'common_space'].includes(room.room_type)) {
                    if (room.room_number === 0) {
                        // Nothing to do, it is valid
                        return;
                    }
                    if (!room_numbers.includes(0)) {
                        room.room_number = 0
                        return;
                    }
                }
                if (!room.room_number) {
                    room.room_number = Math.max(0, ...room_numbers) + 1;
                }
            }
            if ('id' in room) {
                if (room.is_delete) {
                    // The room should be deleted from API
                    try {
                        await Editing.delete_room(vm.listing_id, room.id)
                        delete room.id;
                        room_numbers_delete(room.room_number);
                        console.log(`Deleted ${room}`)
                    } catch (e) {
                        console.error(`(E) Deleted ${room}`, e)
                        throw e    
                    }
                } else {
                    // The room should be updated
                    const old_room_number = room.room_number;
                    try {
                        if (!['living_room', 'bedroom'].includes(room.room_type)) {
                            delete room.beds;
                        }
                        room_update_room_number(room);
                        await Editing.update_room(vm.listing_id, room.id, room)
                        if (old_room_number !== room.room_number) {
                            room_numbers_delete(old_room_number);
                            room_numbers.push(room.room_number);
                        }
                        console.log(`Updated ${room}`);
                    } catch (e) {
                        console.error(`(E) Updated ${room}`, e);
                        room.room_number = old_room_number;
                        throw e
                    }
                }
            } else if (room.is_delete) {
                // It is a room created in the modal but not pushed
                // and it was deleted from the modal afterward.
                // Nothing to do. NEXT
                console.log(`Skipping ${room}`);
            } else {
                // The room should be created
                if (!['living_room', 'bedroom'].includes(room.room_type)) {
                    delete room.beds;
                }
                // We are very cautious.
                // room.room_number should always be null.
                const old_room_number = room.room_number;
                room_update_room_number(room);
                try {
                    const response = await Editing.create_room(room, vm.listing_id)
                    room.id = response.id
                    if (old_room_number !== room.room_number) {
                        room_numbers_delete(old_room_number);
                        room_numbers.push(room.room_number);
                    }
                    console.log(`Created ${room}`)
                } catch (e) {
                    console.error(`(E) Created ${room}`, e);
                    room.room_number = old_room_number;
                    throw e    
                }
            }
        }

        vm.in_progress = false;
        vm.edit_rooms = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.listing_rooms = _.cloneDeep(vm.listing.listing_rooms)

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/rooms.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = async function () {
                modal_scope.error = false
                vm.in_progress = true
                const room_numbers = modal_scope.listing_rooms.map(r => r.room_number)

                modal_scope.error = vm.check_room_validity(modal_scope.listing_rooms);

                if(modal_scope.error.value === true) {
                    vm.in_progress = false
                    return
                }

                const retry_promise = async (n, p) => {
                    let last_error;
                    while (n > 0) {
                        try {
                            return await p();
                        } catch (e) {
                            last_error = e;
                            n -= 1;
                        }
                    }
                    throw last_error;
                }


                await Promise.all(modal_scope.listing_rooms.map(r => {
                    return retry_promise(3, () => vm.apply_room_update(r, room_numbers));
                }));

                var room_summary = {
                    'bedroom_number' : 0,
                    'bed_number' : 0,
                    'bathroom_number' : 0,
                    'additionnal_area_number' : 0,
                    'shared_space' : true,
                }
                modal_scope.listing_rooms.forEach((room, index) => {

                    if(room.is_delete) {
                        console.log('is_delete', index)
                    } else if(room.room_type === 'bedroom') {
                        room_summary.bedroom_number += 1
                        room_summary.bed_number += room.beds.length
                    } else if (room.room_type === 'living_room') {
                        if(room.beds){
                            room_summary.bed_number += room.beds.length
                        }
                    } else if (room.room_type === 'full_bathroom'){
                        room_summary.bathroom_number += 1
                    } else if (room.room_type === 'half_bathroom'){
                        room_summary.bathroom_number += 0.5
                    } else {
                        room_summary.additionnal_area_number += 1
                    }

                    if(!room.is_private) {
                        room_summary.shared_space = false;
                    }
                })

                try {
                    await Editing.update_room_numbers(vm.listing_id, room_summary)

                    vm.listing.listing.bedrooms = room_summary.bedroom_number
                    vm.listing.listing.beds = room_summary.bed_number
                    vm.listing.listing.bathrooms = room_summary.bathroom_number
                    vm.listing.additionnal_area = room_summary.additionnal_area_number
                    vm.listing.shared_space = room_summary.shared_space
                    vm.listing.listing_rooms = modal_scope.listing_rooms
                    console.log('Rooms numbers updated');
                } catch (e) {
                    console.log('An error occurs. Error: ', e)
                }

                vm.in_progress = false
                $uibModalStack.dismissAll();
            }

            modal_scope.create_new_room = function () {
                modal_scope.listing_rooms.push({
                    'listing_id': vm.listing_id,
                    'room_number': null,
                    'beds': [{
                        "type": null,
                        "quantity": 1
                    }],
                    'rooms_amenities': null,
                    'room_type': null,
                    'is_private': false
                })
            }

            modal_scope.delete_room = function (index) {
                modal_scope.listing_rooms[index].is_delete = true;
            }
        }

        vm.edit_property_type = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.listing = {...vm.listing.listing, error: false}
            modal_scope.get_property_types = () => {
                return {
                    apartments: ['apartment', 'condominium', 'serviced_apartment', 'loft', 'casa_particular'],
                    bnb: ['bnb', 'farm_stay', 'lodge', 'casa_particular', 'minsu', 'ryokan'],
                    boutique_hotels_and_more: ['boutique_hotel', 'aparthotel', 'hostel', 'hotel', 'lodge',
                        'kezhan', 'resort', 'serviced_apartment', 'heritage_hotel'],
                    houses: ['bungalow', 'cabin', 'chalet', 'cottage', 'dome_house',
                        'earthhouse', 'farm_stay', 'house', 'houseboat', 'hut',
                        'lighthouse', 'tiny_house', 'townhouse', 'villa', 'casa_particular',
                        'cycladic_house', 'dammuso', 'shepherds_hut', 'trullo', 'pension'],
                    secondary_units: ['guesthouse', 'guest_suite', 'farm_stay'],
                    unique_homes: ['barn', 'boat', 'rv', 'campsite', 'castle', 'cave',
                        'dome_house','earthhouse', 'farm_stay', 'houseboat', 'hut',
                        'igloo', 'island', 'lighthouse', 'plane', 'tent',
                        'tiny_house', 'tipi', 'train', 'treehouse', 'windmill',
                        'yurt', 'pension', 'shepherds_hut', 'ranch', 'holiday_park',
                        'tower', 'riad', 'religious_building', 'shipping_container']
                }[modal_scope.listing.property_type_group]
            }

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/property_type.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                if(modal_scope.listing.property_type_category === null){
                    modal_scope.listing.error = true
                    return
                }
                delete modal_scope.listing.error
                Editing.edit_listing(vm.listing_id, modal_scope.listing)
                    .then(function (response) {
                        vm.listing.listing = modal_scope.listing
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                    console.log('An error occurs. Unable to save the address. Error: ', response)
                });
            }
            return deferred.promise;
        }

        vm.check_guest_controls_value = function (guest_controls) {
            if(guest_controls.allows_children_as_host === undefined) {
                guest_controls.allows_children_as_host = false;
                guest_controls.children_not_allowed_details = "";
            }
            if(guest_controls.allows_infants_as_host === undefined) {
                guest_controls.allows_infants_as_host = false;
                guest_controls.children_not_allowed_details = "";
            }
            if(guest_controls.allows_smoking_as_host === undefined) {
                guest_controls.allows_smoking_as_host = false;
            }
            if(guest_controls.allows_pets_as_host === undefined) {
                guest_controls.allows_pets_as_host = false;
            }
            if(guest_controls.allows_events_as_host === undefined) {
                guest_controls.allows_events_as_host = false;
            }

            return guest_controls ;
        }

        vm.edit_guest_safety = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();

            modal_scope.fields = vm.guest_safety.list;

            modal_scope.modal_listing_expectations = {};
            Object.keys(vm.listing.booking_settings.listing_expectations_for_guests).forEach (elt => {
                modal_scope.modal_listing_expectations[vm.listing.booking_settings.listing_expectations_for_guests[elt].type] =
                    {...vm.listing.booking_settings.listing_expectations_for_guests[elt], is_present: true};
            })


            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/guest_safety.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function(){
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {

                var new_listing_expectations = [];

                Object.keys(modal_scope.modal_listing_expectations).forEach(elt => {
                    if (modal_scope.modal_listing_expectations[elt].is_present) {
                        new_listing_expectations.push({
                            'type': elt,
                            'added_details':modal_scope.modal_listing_expectations[elt].added_details
                        });
                    }
                })

                modal_scope.booking_settings = {...vm.listing.booking_settings};
                modal_scope.booking_settings.listing_expectations_for_guests = new_listing_expectations;
                if(modal_scope.booking_settings.check_in_time_start = 'NOT_SELECTED') {
                    modal_scope.booking_settings.check_in_time_start = 'FLEXIBLE'
                }
                modal_scope.booking_settings.check_in_time_end = 'FLEXIBLE'

                modal_scope.booking_settings.guest_controls = vm.check_guest_controls_value(modal_scope.booking_settings.guest_controls);

                Editing.edit_booking_settings(vm.listing_id, modal_scope.booking_settings)
                    .then(function (response) {
                        vm.listing.booking_settings = modal_scope.booking_settings;
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                    console.log('An error occurs. Unable to save the amenities modifications. Error: ', response)
                });
            }
            return deferred.promise;
        }

        /*
            END LISTING BASIC
         */

        /*
            PRICING AND AVAILABILITY
         */

        vm.edit_discount = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.pricing_settings = {...vm.listing.pricing_settings, error: false}

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/discount.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                const pricing_settings = {
                    weekly_price_factor: (100-modal_scope.pricing_settings.weekly_price_factor)/100,
                    monthly_price_factor: (100-modal_scope.pricing_settings.monthly_price_factor)/100
                }
                if(pricing_settings.weekly_price_factor < pricing_settings.monthly_price_factor){
                    modal_scope.pricing_settings.error = true
                    return
                }
                vm.currency = modal_scope.pricing_settings.listing_currency;
                delete modal_scope.pricing_settings.listing_currency;

                Editing.edit_pricing_settings(vm.listing_id, pricing_settings)
                    .then(function (response) {
                        vm.listing.pricing_settings = modal_scope.pricing_settings;
                        Editing.edit_currency(vm.listing_id, vm.currency)
                            .then(function(response) {
                                vm.listing.pricing_settings.listing_currency = vm.currency;
                                $uibModalStack.dismissAll();
                            }).catch(function (response) {
                                console.log('An error occurs. Unable to save the currency. Error: ', response)
                            })
                    }).catch(function (response) {
                        if (response.status == 400) {

                            var title = 'Attention !';
                            var body = 'Please make your monthly discount at least as high as your weekly.';

                            Modal.inform(title, body);
                        }
                    console.log('An error occurs. Unable to save the discount rates. Error: ', response)
                });
            }
            return deferred.promise;
        }

        vm.edit_trip_length = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.availability_rules = {...vm.listing.availability_rules}

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/trip_length.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {

                Editing.edit_availability_rules(vm.listing_id, modal_scope.availability_rules)
                    .then(function (response) {
                        vm.listing.availability_rules = modal_scope.availability_rules
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                    if (response.status == 400) {

                        var title = 'Attention !';
                        var body = 'Maximum night number cannot be less than minimum night number.';

                        Modal.inform(title, body);
                    }
                    console.log('An error occurs. Unable to save the trip length. Error: ', response)
                });
            }
            return deferred.promise;
        }

        vm.get_availability_period = () => {
            const value = vm.listing?.availability_rules?.max_days_notice?.days
            const period = vm.availability_window_periods.find(e => parseInt(e.value) === parseInt(value))
            if(!period){
                return ''
            }
            return period.label
        }

        vm.edit_calendar_availability = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.availability_rules = _.cloneDeep(vm.listing.availability_rules)
            modal_scope.periods = vm.availability_window_periods

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/calendar_availability.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {

                Editing.edit_availability_rules(vm.listing_id, modal_scope.availability_rules)
                    .then(function (response) {
                        vm.listing.availability_rules = modal_scope.availability_rules
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                    if (response.status == 400) {

                        var title = 'Attention !';
                        var body = 'Maximum night number cannot be less than minimum night number.';

                        Modal.inform(title, body);
                    }
                    console.log('An error occurs. Unable to save the trip length. Error: ', response)
                });
            }
            return deferred.promise;
        }

        /*
            END PRICING AND AVAILABILITY
         */

        /*
            POLICIES AND RULES
         */

        vm.edit_security_deposit = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.other_informations = _.cloneDeep(vm.listing.other_informations)

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/security_deposit.modal.html'),
                scope: modal_scope,
                });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                modal_scope.other_informations.listing_id = vm.listing_id;

                Place.update(modal_scope.other_informations).then(() => {
                    vm.listing.other_informations = modal_scope.other_informations
                    $uibModalStack.dismissAll();
                })

            }
            return deferred.promise;
        }

        vm.edit_airbnb_house_rules = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.booking_settings = {...vm.listing.booking_settings}

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/house_rules.modal.html'),
                scope: modal_scope,
                });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                //TEMPORARY
                modal_scope.booking_settings.guest_controls.children_not_allowed_details = ""
                if(modal_scope.booking_settings.check_in_time_start = 'NOT_SELECTED') {
                    modal_scope.booking_settings.check_in_time_start = 'FLEXIBLE'
                }
                modal_scope.booking_settings.check_in_time_end = 'FLEXIBLE'

                modal_scope.booking_settings.guest_controls = vm.check_guest_controls_value(modal_scope.booking_settings.guest_controls);

                Editing.edit_booking_settings(vm.listing_id, modal_scope.booking_settings)
                    .then(function (response) {
                        vm.listing.booking_settings = modal_scope.booking_settings
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                        console.log('An error occurs. Unable to save house rules infos. Error: ', response)
                });
            }
            return deferred.promise;
        }

        vm.edit_additional_rules = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.listing_description = {...vm.listing.listing_description}

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/additional_rules.modal.html'),
                scope: modal_scope,
                });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {

                Editing.edit_description(vm.listing_id, modal_scope.listing_description)
                    .then(function (response) {
                        vm.listing.listing_description = modal_scope.listing_description
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                        console.log('An error occurs. Unable to save additional house rules infos. Error: ', response)
                });
            }
            return deferred.promise;
        }

        /*
            END POLICIES AND RULES
         */

        /*
            GUEST INFOS
         */

        vm.edit_check_time = function (context) {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.booking_settings = {...vm.listing.booking_settings};
            if(modal_scope.booking_settings.check_out_time !== undefined) {
                modal_scope.booking_settings.check_out_time = modal_scope.booking_settings.check_out_time.toString()
            }

            modal_scope.checkin_day_hours = [{label: 'Flexible', value: 'FLEXIBLE'}].concat(vm.day_hours);
            modal_scope.checkout_day_hours = vm.day_hours;
            modal_scope.title = context;

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/check_time.modal.html'),
                scope: modal_scope,
              });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                modal_scope.booking_settings.check_out_time = parseInt(modal_scope.booking_settings.check_out_time);
                modal_scope.booking_settings.check_in_time_end = 'FLEXIBLE'

                modal_scope.booking_settings.guest_controls = vm.check_guest_controls_value(modal_scope.booking_settings.guest_controls);

                if(modal_scope.booking_settings.check_in_time_start === 'NOT_SELECTED') {
                    var title = 'Attention !';
                    var body = 'Please, complete at least check-in time.';

                    Modal.inform(title, body);
                }
                Editing.edit_booking_settings(vm.listing_id, modal_scope.booking_settings)
                    .then(function (response) {
                        vm.listing.booking_settings = modal_scope.booking_settings;
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                        console.log('An error occurs. Unable to save checkin and checkout infos. Error: ', response);
                });
            }
            return deferred.promise;
        }

        vm.edit_checkin_instruction = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.listing = {...vm.listing.listing};

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/checkin_instructions.modal.html'),
                scope: modal_scope,
              });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                if (!modal_scope.listing.check_in_option.instruction){
                    modal_scope.listing.check_in_option.instruction = "";
                }
                Editing.edit_listing(vm.listing_id, modal_scope.listing)
                    .then(function (response) {
                        vm.listing.listing = modal_scope.listing;
                        $uibModalStack.dismissAll();
                    }).catch(function (response) {
                        console.log('An error occurs. Unable to save checkin instructions. Error: ', response);
                });
            }
            return deferred.promise;
        }

        /*
            END GUEST INFOS
         */

        /*
            OTHER INFORMATIONS
         */

        vm.edit_other_guests = function(){
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.other_informations = _.cloneDeep(vm.listing.other_informations)

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/other_guests.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function (form) {
                modal_scope.other_informations.listing_id = vm.listing_id;
                if(form.$valid){
                    Place.update_other_informations(modal_scope.other_informations).then(() => {
                        vm.listing.other_informations = modal_scope.other_informations
                        $uibModalStack.dismissAll();
                    })
                }

            }
            return deferred.promise;
        }

        vm.edit_other_guest_booking = function(){
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.other_informations = _.cloneDeep(vm.listing.other_informations)

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/other_guest_booking.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function (form) {
                modal_scope.other_informations.listing_id = vm.listing_id;
                if(form.$valid){
                    Place.update_other_informations(modal_scope.other_informations).then(() => {
                        vm.listing.other_informations = modal_scope.other_informations
                        $uibModalStack.dismissAll();
                    })
                }
            }
            return deferred.promise;
        }

        vm.edit_house_rules = function(){
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.other_informations = _.cloneDeep(vm.listing.other_informations)

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/other_house_rules.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                modal_scope.other_informations.listing_id = vm.listing_id;
                Place.update_other_informations(modal_scope.other_informations).then(() => {
                    vm.listing.other_informations = modal_scope.other_informations
                    $uibModalStack.dismissAll();
                })
            }
            return deferred.promise;
        }

        vm.edit_cleanings_how = function(){
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.other_informations = _.cloneDeep(vm.listing.other_informations)

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/other_cleanings_how.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                modal_scope.other_informations.listing_id = vm.listing_id;
                Place.update_other_informations(modal_scope.other_informations).then(() => {
                    vm.listing.other_informations = modal_scope.other_informations
                    $uibModalStack.dismissAll();
                })
            }
            return deferred.promise;
        }

        vm.edit_cleanings = function(){
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.other_informations = _.cloneDeep(vm.listing.other_informations)

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/other_cleanings.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                modal_scope.other_informations.listing_id = vm.listing_id;
                Place.update_other_informations(modal_scope.other_informations).then(() => {
                    vm.listing.other_informations = modal_scope.other_informations
                    $uibModalStack.dismissAll();
                })
            }
            return deferred.promise;
        }

        /*
            END OTHER INFORMATIONS
         */

        /*
            ADMIN
         */

        vm.edit_listing_status = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.listing = {...vm.listing.listing};

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/listing_status.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {
                const publish_fn = modal_scope.listing.has_availability ? Editing.list_place : Editing.unlist_place

                publish_fn(vm.listing_id)
                    .then(function(response){
                        vm.listing.listing = modal_scope.listing
                        $uibModalStack.dismissAll();
                    }, function({data}){
                        $uibModalStack.dismissAll();
                        if(data.error_code === 400 && data.error_type === 'validation'){
                            const validation = `https://www.airbnb.com/verify-listing/${vm.listing_id}`
                            vm.rejected_listing({}, `
                                <a 
                                    href="${validation}" 
                                    target="_blank"
                                    class="external-link">
                                        You need to register your place before you can list it
                                </a>
                            `)
                            return
                        }
                        vm.rejected_listing(data.errors, data.error_message)
                    })
            }
            return deferred.promise;
        }

        vm.rejected_listing = function (errors, error_message) {

            var modal_scope = $scope.$new();
            modal_scope.errors = errors;
            modal_scope.error_message = {
                value: error_message
            }

            $uibModal.open({
                templateUrl: require('./modals/listing_status_rejected.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function (){
                $uibModalStack.dismissAll();
            }

        }

        vm.recover_incomplete_field = function () {
            // Backend responsibility.
        }

        vm.edit_pricing = function () {
            var deferred = $q.defer();
            var modal_scope = $scope.$new();
            modal_scope.pricing_settings = {...vm.listing.pricing_settings};

            modal_scope.modal = $uibModal.open({
                templateUrl: require('./modals/pricing.modal.html'),
                scope: modal_scope,
            });

            modal_scope.cancel = function () {
                $uibModalStack.dismissAll();
            }

            modal_scope.edit = function () {

                vm.currency = modal_scope.pricing_settings.listing_currency;
                delete modal_scope.pricing_settings.listing_currency;

                // if(modal_scope.pricing_settings.default_daily_price === undefined) {
                //     var title = 'Attention !';
                //     if(vm.currency === "EUR") {
                //         var body = 'Please use a base price of at least € 11 but no more than € 103,187.';
                //     } else if(vm.currency === "CHF") {
                //         var body = 'Please use a base price of at least 10 CHF but no more than 98,140 CHF.';
                //     }

                //     Modal.inform(title, body);
                // } else {

                    console.log(modal_scope.pricing_settings)
                    Editing.edit_pricing_settings(vm.listing_id, modal_scope.pricing_settings)
                        .then(function (response) {
                            vm.listing.pricing_settings = modal_scope.pricing_settings;
                            Editing.edit_currency(vm.listing_id, vm.currency)
                                .then(function(response) {
                                    vm.listing.pricing_settings.listing_currency = vm.currency;
                                    $uibModalStack.dismissAll();
                                }).catch(function (response) {
                                    console.log('An error occurs. Unable to save the currency. Error: ', response)
                                })
                        }).catch(function (response) {
                        console.log('An error occurs. Unable to save the price. Error: ', response)
                    });
                // }

            }
            return deferred.promise;
        }

    }]
)
